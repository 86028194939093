<template>
  <div class="page">
    <list-layout
      ref="activityFormListRef"
      :filter-form="filterForm"
      :thead="thead"
      :on-fetch="onFetch"
      :on-delete="onDelete"
      :on-edit="toDesign"
      @command="onCommand"
    >
      <template #reminder>
        <Reminder
          text="已选择活动中有已结束的活动，则无法编辑表单，可通过复制修改表单内容"
        ></Reminder>
      </template>
      <template #filter>
        <activity-form-list-filter
          :filter-form="filterForm"
          :upload-filter="onFilter"
        ></activity-form-list-filter>
      </template>

      <template #top>
        <el-button
          class="top-btn"
          type="primary"
          size="small"
          @click="toDesign()"
          >创建表单</el-button
        >
      </template>

      <!--      <template #tabletop="{idList}">-->
      <!--        <el-button type="danger" size="small" @click="onDelete(idList)">删除</el-button>-->
      <!--      </template>-->

      <template #item_activities_count="{ row }">
        <span>{{ row.activities_count }}</span>
        <!--        <el-button-->
        <!--          v-if="row.activities_count > 0"-->
        <!--          @click="viewActivities(row)"-->
        <!--          style="margin-left: 10px"-->
        <!--          type="text"-->
        <!--          >查看</el-button-->
        <!--        >-->
        <i
          v-if="row.activities_count > 0"
          style="margin-left: 10px"
          @click="viewActivities(row)"
          class="el-icon-view cursor-pointer"
        ></i>
      </template>

      <template #item_name="{ row }">
        <!--        <span :title="row.name" class="nowrap">{{ row.name }}</span>-->
        <ListInputPopover
          class="number-input"
          :integer="false"
          type="string"
          :use-hover="false"
          :button-loading="btnLoading"
          v-model="row.name"
          @change="changeName(row)"
        ></ListInputPopover>
        <!--        <div class="flex flex-align-center">-->
        <!--          <span :title="row.name" class="nowrap">{{ row.name }}</span>-->
        <!--          <el-button-->
        <!--              @click="changeName(row)"-->
        <!--              style="margin-left: 10px"-->
        <!--              type="text"-->
        <!--          >修改</el-button-->
        <!--          >-->
        <!--        </div>-->
      </template>
    </list-layout>

    <activity-form-activities
      :show="activityDialog.show"
      v-bind="activityDialog.data || {}"
      @close="activityDialog.show = false"
    ></activity-form-activities>
  </div>
</template>

<script>
import ListLayout from '../../../../base/layout/ListLayout'
import {
  deleteActivityForm,
  getActivityFormDetail,
  getActivityFormList,
  setActivityFormDetail,
} from '../../api/activity-form'
import ActivityFormListFilter from '../../components/activity-form/ActivityFormListFilter'
import ActivityFormActivities from '../../components/activity-form/ActivityListDialog'
import Reminder from '../../../common/components/Reminder'
import activityForm from '../../mixin/activityForm'
import ListInputPopover from '../../../../base/components/List/ListInputPopover'
export default {
  components: {
    ListInputPopover,
    Reminder,
    ActivityFormActivities,
    ActivityFormListFilter,
    ListLayout,
  },
  mixins: [activityForm],
  data() {
    return {
      filterForm: {
        page: 1,
        page_size: 15,
        min_activity: -1,
        max_activity: -1,
        start_time: -1,
        end_time: -1,
        name: '',
        order_by: 'id', //id,create_time,activities_count
        is_desc: 1,
      },
      thead: [
        { type: 'selection', width: 60 },
        { label: '表单ID', prop: 'id', width: 100 },
        { label: '表单名称', prop: 'name', type: 'slot', minWidth: 180 },
        {
          label: '已选择活动数',
          prop: 'activities_count',
          minWidth: 150,
          type: 'slot',
          sortable: true,
        },
        {
          label: '创建时间',
          prop: 'create_time',
          minWidth: 160,
          sortable: true,
        },
        {
          label: '操作',
          type: 'operation',
          width: 150,
          operations: [
            {
              command: 'edit',
              text: '编辑',
              disabled: (row) => row.can_edit === 0,
            },
            {
              command: 'copy',
              text: '复制',
            },
            { command: 'delete', disabled: (row) => row.activities_count > 0 },
          ],
        },
      ],
      activityDialog: {
        show: false,
        data: null,
      },

      btnLoading: false,
    }
  },
  mounted() {
    let that = this
    // onShow
    this._visibilitychange_addEventListener = function () {
      if (document.visibilityState === 'visible') {
        console.log('浏览器的当前页签onShow时，do something')
        that.onFilter()
      }
    }
    document.addEventListener(
      'visibilitychange',
      this._visibilitychange_addEventListener
    )
  },
  destroyed() {
    document.removeEventListener(
      'visibilitychange',
      this._visibilitychange_addEventListener
    )
  },
  methods: {
    onFetch(params) {
      return getActivityFormList(params)
    },
    onFilter(params = {}) {
      this.$refs.activityFormListRef.getList({ ...params, page: 1 })
    },
    onDelete(ids) {
      // if (ids.length) {
      return new Promise((resolve) => {
        Promise.all(
          ids.map(
            (id) =>
              new Promise((resolve1) => {
                deleteActivityForm({ id: id })
                  .then(({ msg }) => {
                    this.$message.success(msg)
                  })
                  .finally(() => resolve1())
              })
          )
        )
          .then(() => {
            // resolve({
            //   code: 1,
            //   msg: '删除成功',
            //   data: null,
            // }) // 并不能保证批量都成功
            resolve({})
          })
          .catch(() => {
            resolve({})
          })
      })
      // }
      // return deleteActivityForm({ id: ids[0] })
    },
    changeName(row) {
      // this.$prompt('请输入表单名称', '提示', {
      //   confirmButtonText: '保存',
      //   cancelButtonText: '取消',
      //   inputPattern: /.+/,
      //   inputErrorMessage: '请输入表单名称',
      //   // inputValue: this.pageTitle,
      //   inputPlaceholder: row.name,
      // })
      //   .then(({ value }) => {
      //     this.save(row, value)
      //   })
      //   .catch(() => {
      //     // this.$message({
      //     //   type: 'info',
      //     //   message: '取消保存',
      //     // })
      //   })
      this.save(row, row.name)
    },
    // 获取配置详情
    getFormConfig(id) {
      return new Promise((resolve) => {
        getActivityFormDetail({
          id: id,
        })
          .then(({ data }) => {
            this.rowData = data
            resolve()
          })
          .catch(() => {
            resolve()
          })
      })
    },
    async save(row, title = '') {
      this.btnLoading = true
      await this.getFormConfig(row.id)
      setActivityFormDetail({
        id: row.id,
        name: title,
        form_config: JSON.stringify(row.form_config),
        page_config: JSON.stringify(this.rowData.page_config || {}),
      })
        .then(({ code, msg }) => {
          if (code) {
            this.onFilter({})
          } else {
            this.$message.error(msg)
          }
        })
        .finally(() => {
          this.btnLoading = false
        })
    },
    viewActivities(row) {
      this.activityDialog.data = row
      this.activityDialog.show = true
    },
  },
}
</script>

<style lang="scss" scoped>
.nowrap {
  @include nowrap();
}

.el-icon-view {
  color: $primary;
}
</style>
