<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="auto"
      label-position="left"
      size="medium"
      class="filter-form grid"
    >
<!--      <div class="filter-item">-->
        <el-form-item label="表单名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="已选择活动数：" prop="min_activity">
          <input type="hidden" :value="form.min_activity" />
          <el-select v-model="countRange" placeholder="请选择" clearable>
            <el-option
              v-for="item in rangeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="max_activity">
          <input type="hidden" :value="form.max_activity" />
        </el-form-item>
<!--      </div>-->
<!--      <div class="filter-item">-->
        <DatePeriodPicker v-if="filterForm.start_time !== undefined"
          label="创建时间："
          start-prop="start_time"
          :start-time.sync="form.start_time"
          end-prop="end_time"
          :end-time.sync="form.end_time"
          style="grid-column: 1 / span 3"
        />
<!--      </div>-->
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
export default {
  name: 'ActivityFormListFilter',
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  model: {
    prop: 'filterForm',
    event: 'updateFilter',
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      rangeList: [
        // {
        //   id: 0,
        //   name: '全部',
        // },
        {
          id: 1,
          name: '1-10',
        },
        {
          id: 2,
          name: '10-20',
        },
        {
          id: 3,
          name: '20-50',
        },
        {
          id: 4,
          name: '50-100',
        },
        {
          id: 5,
          name: '100以上',
        },
      ],
    }
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {
        this.$emit('updateFilter', val)
      },
    },
    countRange: {
      get() {
        return this.getFormRange('activities_count')
      },
      set(val) {
        this.setFormRange('activities_count', val)
      },
    },
  },
  methods: {
    setFormRange(key, rangeIndex) {
      if (key === 'activities_count') {
        switch (rangeIndex) {
          case 1:
            {
              this.form.min_activity = 0
              this.form.max_activity = 10
            }
            break
          case 2:
            {
              this.form.min_activity = 10
              this.form.max_activity = 20
            }
            break
          case 3:
            {
              this.form.min_activity = 20
              this.form.max_activity = 50
            }
            break
          case 4:
            {
              this.form.min_activity = 50
              this.form.max_activity = 100
            }
            break
          case 5:
            {
              this.form.min_activity = 100
              this.form.max_activity = -1
            }
            break
          default: {
            this.form.min_activity = -1
            this.form.max_activity = -1
          }
        }
      }
    },
    getFormRange(key) {
      if (key === 'activities_count') {
        let countRange
        const { min_activity: min, max_activity: max } = this.form
        if (min === -1 && max === -1) {
          countRange = ''
        } else if (max === 10) {
          countRange = 1
        } else if (max === 20) {
          countRange = 2
        } else if (max === 50) {
          countRange = 3
        } else if (max === 100) {
          countRange = 4
        } else if (min === 100) {
          countRange = 5
        }
        return countRange
      }
      return -1
    },
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-form.grid {
  grid-template-columns: repeat(3, minmax(auto, 320px));
}
</style>
