<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :onExport="showExport ? onExport : null" :toggleCollapseEnable="showToggle">
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-form grid"
      @submit.native.prevent
    >
<!--      <div class="filter-item">-->
        <!-- <el-form-item label="用户名称：" prop="user_keyword">
          <el-input v-model="form.user_keyword" placeholder="请输入用户名称"></el-input>
        </el-form-item> -->
        <el-form-item label="活动名称：" prop="activity_keyword">
          <el-input
            v-model="form.activity_keyword"
            placeholder="请输入活动名称"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="filterForm.type !== undefined" label="活动类型：" prop="type">
          <el-select v-model="type" placeholder="请选择">
            <el-option
              v-for="item in TypeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="filterForm.type !== undefined" label="报名类型：" prop="sign_type">
          <el-select v-model="sign_type" placeholder="请选择">
            <el-option
              v-for="item in SignTypeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
<!--      </div>-->
<!--      <div class="filter-item">-->
        <el-form-item label="活动状态：" prop="status">
          <el-select v-model="status" placeholder="请选择">
            <el-option
              v-for="item in StatusArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="filterForm.category_id !== undefined" label="活动分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择">
            <el-option
              v-for="item in categoryOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="filterForm.organization_id !== undefined" label="所属架构：" prop="organization_id">
          <!-- <el-select v-model="organization_id" placeholder="请选择">
            <el-option
              v-for="item in ParentArr"
              :key="item.status"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <el-cascader
            v-model="organization_id"
            :options="selectPositionOptions"
            :props="{
              checkStrictly: true,
              expandTrigger: 'hover',
              label: 'name',
              value: 'id',
              emitPath: false,
            }"
            filterable
            @change="handleExportCatalogChange"
            ref="CascaderRef"
          >
          </el-cascader>
        </el-form-item>
<!--      </div>-->

<!--      <div v-if="filterForm.start_time !== undefined" class="filter-item">-->
        <DatePeriodPicker v-if="filterForm.start_time !== undefined"
          label="活动时间："
          start-prop="start_time"
          :start-time.sync="form.start_time"
          end-prop="end_time"
          :end-time.sync="form.end_time"
                          style="grid-column: 1 / span 2"
        />
<!--      </div>-->
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import {
  ParentList,
  documentActivityList,
  getCategory,
} from '../../api/activity-list/list'
import {
  getOrganizationsTree,
} from '../../api/activity-list/detail'
export default {
  name: 'activity-list-filter',
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    showExport: {
      type: Boolean,
      default: true
    },
    showToggle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rules: {},
      TypeArr: [
        { id: 0, name: '线上活动' },
        { id: 1, name: '线下活动' },
      ],
      SignTypeArr: [
        { id: 0, name: '免费报名' },
        { id: 1, name: '保证金报名' },
        { id: 2, name: '付费报名' },
      ],
      StatusArr: [
        { id: 0, name: '未开始' },
        { id: 1, name: '进行中' },
        { id: 2, name: '已结束' },
        { id: 3, name: '已取消' },
      ],
      ParentArr: [],
      categoryOption: [],
      selectPositionOptions: [],
    }
  },
  methods: {
    // 关闭架构折叠面板
    handleExportCatalogChange() {
      this.$refs.CascaderRef.dropDownVisible = false
    },
    // 获取所属组织
    getParentList() {
      ParentList({ is_show_no_parent: 1, is_show_all_brand: 1 })
        .then((res) => {
          this.ParentArr = res.data
        })
        .catch((err) => {})
    },
    // 获取所属架构
    getPositionOptions() {
      getOrganizationsTree({ is_all: 1, is_show_none: 0,hashid_enable:1 })
        .then((res) => {
          this.selectPositionOptions = res.data
        })
        .catch((err) => {})
    },
    // 获取活动分类
    getCategoryOption() {
      getCategory().then((res) => {
        this.categoryOption = res.data
        this.$emit('categories', this.categoryOption)
      })
    },
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page }
      documentActivityList(postData).catch((err) => {})
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
  },
  created() {
    // this.getParentList();
    this.getPositionOptions()
    this.getCategoryOption()
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {},
    },
    // brand_id: {
    //   get() {
    //     return this.form.brand_id == -1 ? "" : this.form.brand_id;
    //   },
    //   set(val) {
    //     this.form.brand_id = val;
    //   },
    // },
    status: {
      get() {
        return this.form.status == -1 ? '' : this.form.status
      },
      set(val) {
        this.form.status = val
      },
    },
    type: {
      get() {
        return this.form.type == -1 ? '' : this.form.type
      },
      set(val) {
        this.form.type = val
      },
    },
    sign_type: {
      get() {
        return this.form.sign_type == -1 ? '' : this.form.sign_type
      },
      set(val) {
        this.form.sign_type = val
      },
    },
    organization_id: {
      get() {
        return this.form.organization_id == -1 ? '' : this.form.organization_id
      },
      set(val) {
        this.form.organization_id = val
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>

<style lang="scss">

</style>
