export default {
  data() {
    return {

    }
  },
  methods: {
    onCommand({command, row}) {
      switch(command) {
        case 'copy':
          this.toDesign({
            id: 0,
            copy_id: row.id,
            name: row.name + '的副本'
          })
          break
        default:
      }
    },
    toDesign({ id, name, copy_id } = { id: 0 }) {
      let routerData = this.$router.resolve({
        name: 'ActivityFormDesign',
        params: {
          id,
        },
        query: {
          title: encodeURIComponent(name || '新报名表单'),
          // 可选
          ...copy_id ? {
            copy_id
          }:{}
        },
      })
      window.open(routerData.href, '_blank')
    },
  }
}