<template>
  <el-dialog
    v-if="openDialog"
    class="dialog-vertical"
    title="已选择活动"
    width="900px"
    :visible.sync="openDialog"
    close-on-click-modal
    close-on-press-escape
    append-to-body
  >
    <list-layout
      ref="activityListDialog"
      :on-fetch="onFetch"
      :filter-form="filterForm"
      :thead="thead"
      :on-edit="onEdit"
      @command="onCommand"
    >
      <template #filter>
        <activity-list-filter
          :show-export="false"
          :show-toggle="false"
          :upload-filter="onQuery"
          :filter-form="filterForm"
        ></activity-list-filter>
      </template>
    </list-layout>
  </el-dialog>
</template>

<script>
import ListLayout from '../../../../base/layout/ListLayout'
import ActivityListFilter from '../ActivityList/ListFilter'
import { ActivityList } from '../../api/activity-list/list'

export default {
  name: 'activity-form-activities',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    activities: Array,
    id: [String, Number]
  },
  data() {
    return {
      loading: false, // 加载
      thead: [
        /**
         * "id": "PpOXgqQa",
         "name": "【活动留位】“非遗在社区”工作交流活动",
         "activity_status_text": "进行中",
         "activity_status_text_two": 3,
         "activity_time_text": "2024-03-26 00:00 ~ 2024-03-30 00:00"
         */
        { label: 'ID', prop: 'id', width: 100 },
        { label: '活动名称', prop: 'name', minWidth: 100 },
        {
          label: '活动状态',
          type: 'status',
          prop: 'activity_status_text',
          width: 100,
        },
        { label: '活动时间', prop: 'activity_time_text', width: 300 },
        {
          type: 'operation',
          label: '操作',
          operations: [
            // {
            //   command: 'edit',
            // },
            {
              command: 'signup-list',
              text: '报名名单'
            }
          ],
        },
      ],
      // 筛选对象
      filterForm: {
        // start_time: -1, //开始时间
        // end_time: -1, //结束时间
        // user_keyword: '', //用户名
        activity_keyword: '', // 活动名称
        // category_id: '',
        // brand_id: -1, //品牌Id
        status: -1, // 活动状态
        activity_form_id: -1,
        // type: -1, // 活动类型
        // sign_type: -1, //	报名类型
        // tab: 'normal', //当前筛选tab
        // page_size: 15, //每页多少条数据
        // organization_id: -1, //所属架构
      },
    }
  },
  methods: {
    // 点击编辑
    editRow(row) {
      // 判断是进新版本详情还是旧版本详情
      if (this.isNewEdition) {
        // 新的
        this.$router.push({
          name: 'AddActivityNew',
          params: {
            type: 'edit',
            id: row.hashid,
            create_step: row.create_step,
          },
        })
      } else {
        this.$router.push({
          name: 'ActivityDetail',
          params: {
            type: 'edit',
            id: row.hashid,
            create_step: row.create_step,
          },
        })
      }
      // })
    },
    onCommand({row, command}) {
      if (command === 'signup-list') {
        let params = {
          type: 'edit',
          id: row.hashid,
          create_step: row.create_step,
        }

        const routeRawData = {
          name: 'ActivityDetailNew',
          params,
          query: {
            // 一级tab
            activeTab: 'join',
            // 二级tab
            secondTab: 1,
          },
        }
        const routeData = this.$router.resolve(routeRawData)
        window.open(routeData.href, '_blank')
      }
    },
    onEdit(row) {
      this.editRow(row)
    },
    onQuery(query = {}) {
      this.$refs.activityListDialog.getList({ ...query, page: 1 })
    },
    onFetch(query = {}) {
      //活动状态选项数据
      // const statusArr = [
      //   { name: "未开始", id: 0 },
      //   { name: "进行中", id: 1 },
      //   { name: "已结束", id: 2 },
      //   { name: "已取消", id: 3 },
      // ]

      // return Promise.resolve({
      //   code: 1,
      //   msg: '获取成功',
      //   data: {
      //     total: this.activities?.length || 0,
      //     per_page: 9999,
      //     current_page: 1,
      //     last_page: 1,
      //     data: (this.activities || []).filter(ac => {
      //       if (query?.activity_keyword) return ac.name === query.keyword
      //       return true
      //     }).filter(ac => {
      //       if (query.status !== -1) {
      //         return statusArr[query.status].name === ac.activity_status_text
      //       }
      //       return true
      //     }),
      //   },
      // })

      return ActivityList({ ...query, activity_form_id: this.id })
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('close', val)
      },
    },
  },
  components: {
    ActivityListFilter,
    ListLayout,
  },
}
</script>

<style lang="scss" scoped></style>
